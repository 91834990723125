@import "../../styles/mixins.scss";

body {
  background: #f8fcff 0% 0% no-repeat padding-box;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  ::-webkit-scrollbar {
    display: none !important;
    background: transparent !important;
  }
}

body::-webkit-scrollbar {
  display: none !important;
}

.margin20 {
  margin: 20px;
}

.marginAuto {
  margin: auto;
}

.marginLeftRight20 {
  margin: 0 20px !important;
}

.marginLeft10 {
  margin-left: 10px !important;
}

.marginLeft5 {
  margin-left: 5px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginTop30 {
  margin-top: 30px !important;
}

.removeUnderline {
  text-decoration: none;
}

.marginTop {
  margin-top: 5px !important;
}

.paddingTop5 {
  padding-top: 5px;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.grid {
  display: grid;
}

.toolTipWidth {
  width: 50% !important;
}

.textUnderline {
  text-decoration: underline;
}

.contentTextAlign {
  text-align: center !important;
  padding: 20px;
  font-size: 18px;
  font-weight: 400;
}

/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container input:checked~.checkmark {
  background-color: #7165E3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container .checkmark:after {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.gridAlignTextEnd {
  display: grid;
  justify-items: end;
}

.cardStyle {
  height: 115px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #c4c8d066;
  border-radius: 16px;
  opacity: 1;
  cursor: pointer;
  padding: 15px;
}

.bigCardStyle {
  height: calc(100vh - 255px);
  overflow: scroll;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #c4c8d066;
  border-radius: 16px;
  opacity: 1;
  padding-top: 5px;
}

.bigCardFullHeightStyle {
  height: calc(100vh - 125px);
  overflow: scroll;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #c4c8d066;
  border-radius: 16px;
  opacity: 1;
  padding-top: 5px;
}

// .dynamicGrid {
//   display: grid;
//   grid-template-columns: repeat(auto-fill, 1fr);
//   overflow-x: auto;
//   grid-gap: 20px;
//   width: 100%;
// }

.dynamicGrid {
  display: grid;
  height: 100%;
  gap: 10px;
  grid-auto-columns: minmax(8rem, auto);
  grid-auto-flow: column;
  overflow-x: scroll;
}

.grid5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.grid4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.bigCardGrid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 10px;
  margin-top: 20px;
}

.smallCardGrid {
  display: grid;
  grid-template-columns: 80px 1fr;
  grid-gap: 20px;
  margin-top: 20px;
}

.bigCalendarLeftCardWidth {
  margin-right: 15px;
}

.userLogo {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  object-fit: contain;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.redLabel {
  color: red;
}

.darkText {
  color: #52575d !important;
}

.rightAlign {
  text-align: right;
}

.reducedOpacity {
  opacity: 0.4;
}

.userNameStyle {
  text-align: left;
  font: normal normal 600 15px/13px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
}

.loginStatus {
  text-align: left;
  font: italic normal normal 10px/15px Roboto;
  letter-spacing: 0px;
  color: #52575d;
  margin-top: 5px;
  max-width: 100px;
  opacity: 1;
}

.marginLeft20 {
  margin-left: 10px;
}

.marginLeft {
  margin-left: 20px !important;
}

.marginLeft30 {
  margin-left: 30px;
}

.marginLeft40 {
  margin-left: 40px !important;
}

.reduce10Left {
  margin-left: -10px;
}

.reduce25Left {
  margin-left: -25px;
}

.chevronRightStyle {
  width: 18px;
  height: 18px;
  background: #ebf2f7 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
  margin-left: 15px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.warningTextAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spaceAround {
  display: flex;
  justify-content: space-around;
}

.green {
  color: #00c07f;
}

.yellow {
  color: #fec106;
}

.orange {
  color: orange;
}

.searchBarStyle {
  background: #e3e5f1 0% 0% no-repeat padding-box;
  border: 1px solid #a7a7a7;
  height: 35px;
  width: 34vw;
  border-radius: 22px;
  opacity: 0.3;
  display: flex;
  justify-content: space-between;

  p {
    margin-top: 8px;
    margin-left: 10px;
  }
}

.displayInRow {
  display: flex;
  flex-direction: row;
}

.displayInGrid {
  display: grid;
  justify-items: center;
  align-items: center;
}

.smallIcons {
  height: 30px;
  width: 30px;
  margin-left: 20px;
}

.fileIcon {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.documentPageHeader {
  background: #beb9f2 0% 0% no-repeat padding-box;
  height: 50px;
  width: 96%;
  margin-left: 2%;
  border-radius: 6px;
  text-align: left;
  display: grid;
  grid-template-columns: 10% 25% 20% 20% 20% 5%;
}

.documentPageHeaderWithoutSite {
  background: #beb9f2 0% 0% no-repeat padding-box;
  height: 50px;
  width: 96%;
  margin-left: 2%;
  border-radius: 6px;
  text-align: left;
  display: grid;
  grid-template-columns: 10% 35% 25% 25% 5%;
}

.contractWidth {
  width: 200px;
}

.activeContractGrid {
  display: grid;
  grid-template-columns: 6% 6% 12% 10% 3% 16% 10% 11% 11% 5%;
  grid-gap: 10px;
}

.activeContractGridWithoutAction {
  display: grid;
  grid-template-columns: 6% 6% 16% 11% 18% 11% 11% 11%;
  grid-gap: 10px;
}

.activeContractGridForPrint {
  display: grid;
  grid-template-columns: 6% 6% 14% 11% 3% 16% 11% 12% 12%;
  grid-gap: 1%;
}

.draftContractGrid {
  display: grid;
  grid-template-columns: 5% 11% 11% 16% 11% 10% 5% 10% 10% 5%;
  grid-gap: 0.4%;
}

.draftContractGridForPrint {
  display: grid;
  grid-template-columns: 5% 12% 12% 16% 11% 11% 5% 11% 11%;
  grid-gap: 0.4%;
}

.activationPendingContractGrid {
  display: grid;
  grid-template-columns: 5% 10% 9% 10% 6% 10% 5% 8% 10% 10% 5%;
  grid-gap: 10px;
}

.activationPendingContractGridForPrint {
  display: grid;
  grid-template-columns: 5% 11% 11% 10% 6% 11% 5% 9% 11% 11%;
  grid-gap: 1%;
}

.upcomingContractGrid {
  display: grid;
  grid-template-columns: 5% 10% 10% 15% 10% 10% 10% 15% 5%;
  grid-gap: 10px;
}

.upcomingContractGridForPrint {
  display: grid;
  grid-template-columns: 5% 11% 11% 15% 11% 11% 11% 15%;
  grid-gap: 1%;
}

.expiredContractGrid {
  display: grid;
  grid-template-columns: 5% 12% 10% 15% 10% 12% 10% 12% 5%;
  grid-gap: 1%;
}

.expiredContractGridForPrint {
  display: grid;
  grid-template-columns: 5% 12% 11% 15% 11% 13% 11% 13%;
  grid-gap: 1%;
}

.documentDataProof {
  background: #f5f5f8 0% 0% no-repeat padding-box;
  width: 96%;
  margin-left: 2%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  display: grid;
  grid-template-columns: 10% 25% 20% 20% 20% 5%;
  word-break: break-word !important;
}

.documentDataProofWithoutSite {
  background: #f5f5f8 0% 0% no-repeat padding-box;
  width: 96%;
  margin-left: 2%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  display: grid;
  grid-template-columns: 10% 35% 25% 25% 5%;
  word-break: break-word !important;
}

.documentProofTextWidth {
  font: normal normal bold 13px/17px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  text-transform: uppercase;
  opacity: 1;
  text-align: left;
  margin-top: 15px;
}

.documentProofDataTextWidth {
  font: normal normal normal 13px/17px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  opacity: 1;
  display: flex;
  align-items: center;
  text-align: left !important;
  margin-top: 10px;
}

.poweredBy {
  text-align: left;
  font: normal normal normal 11px/14px Montserrat;
  letter-spacing: 0px;
  color: #7165e3;
  opacity: 1;
  margin: 5px 0 0 10px;
}

.marginRight {
  margin-right: 10px;
}

.marginRight5 {
  margin-right: 5px !important;
}

.marginRight20 {
  margin-right: 20px;
}

.welcomeStyle {
  text-align: left;
  font: normal normal bold 20px/18px Montserrat;
  letter-spacing: 0px;
  color: #7165e3;
}

.extensionStyle {
  text-align: left;
  font: normal normal bold 15px/13px Montserrat;
  letter-spacing: 0px;
  color: #7165e3;
}

.extensionBorder {
  background-color: #ebf2f7;
  height: 3px;
}

.extensionOptionsStyle {
  text-align: left;
  font: normal normal medium 15px/13px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  text-transform: uppercase;
  opacity: 0.9;
  margin-top: 10px;
}

.terminationFieldWidth {
  width: 72%;
}

.entityFieldWidth {
  width: 60%;
}

.disabledUploadButton {
  color: rgba(143, 153, 168, 0.5) !important;
  background-color: #f1f2f3;
  border: none;
  border-radius: 6px;
  cursor: not-allowed !important;
}

.addMoreButton {
  // color: rgba(143, 153, 168, 0.5);
  // background-color: #f1f2f3;
  color: white !important;
  background-color: #7165e3;
  height: 32px;
  min-width: 130px;
  border: none;
  border-radius: 6px;
}

.addCotractorButton {
  color: rgba(143, 153, 168, 0.5);
  background-color: #f1f2f3;
  height: 32px;
  width: 240px;
  border: none;
  border-radius: 6px;
  margin-top: 5px;
  margin-right: 10px;
}

.disabled {
  cursor: not-allowed;
}

.continueDisabled {
  cursor: not-allowed !important;
  opacity: 0.3;
}

.addBoxDescription {
  color: #52575d;
  background-color: rgba(143, 153, 168, 0.5);
  padding: 10px;
  border-radius: 10px;
}

.fullWidth {
  width: 90% !important;
}
.halfWidth {
  width: 87% !important;
}

.contractId {
  text-align: left;
  font: normal normal medium 15px/13px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  text-transform: uppercase;
  opacity: 0.9;
  margin: 5px 20px 0 20px;
}

.welcomePadding {
  padding: 30px 40px;
}

.welcomeMessage {
  background: #ebe9fb 0% 0% no-repeat padding-box;
  text-align: left;
  font: normal normal 300 13px/15px Montserrat;
  letter-spacing: 0.9px;
  color: #52575d;
  padding: 20px;
}

.addContractBody {
  background-color: white;
  height: 100vh;
  overflow: scroll;
}

.extensionDialogBackground {
  background-color: white;
  margin: -20px !important;
  padding: 40px;
  border-radius: 10px;
}

.deleteEcecutedContractDialogBackground {
  background-color: white;
  margin: -20px !important;
  padding: 20px;
  border-radius: 10px;
}

.addManagerDialogBackground {
  background-color: white !important;
  padding: 20px 40px;
  border-radius: 20px !important;
}

.contractViewDialogBackground {
  background-color: white !important;
  padding: 20px;
  border-radius: 20px !important;
}

.extentionGrid {
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-gap: 57px;
}

.addManagerGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
}

.diffPaymentGrid {
  display: grid;
  grid-template-columns: 0.05fr 1.5fr 1fr 1fr 0.5fr 1fr;
  grid-gap: 20px;
}

.addOnDiffPaymentGrid {
  display: grid;
  grid-template-columns: 0.05fr 1.5fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

.extentionLableStyle {
  text-align: left;
  font: normal normal normal 13px/17px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 5px;
}

.biggerFont {
  font: normal normal normal 15px/19px Montserrat;
}

.termPeriodGrid {
  display: grid;
  grid-template-columns: 45% 10% 45%;
}

.termPeriodWithAddGrid {
  display: grid;
  grid-template-columns: 1fr 10% 1fr 35px;
  grid-gap: 10px;
}

.contractedTime {
  display: grid;
  grid-template-columns: 30% 65%;
  grid-column-gap: 5%;
}

.effectiveDateWidth {
  width: 45%;
}

.extentionBoxStyle {
  border: 1px solid #7165e3;
  border-radius: 8px;
  opacity: 1;
  width: 100%;
  padding: 20px;
}

.serviceBoxStyle {
  border: 1px solid #7165e3;
  border-radius: 8px;
  opacity: 1;
  height: 60vh;
  overflow: scroll;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
}

.deleteDraftBoxStyle {
  border: 1px solid #7165e3;
  border-radius: 8px;
  opacity: 1;
  height: 20vh;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
}

.newContractFromCloneBoxStyle {
  border: 1px solid #7165e3;
  border-radius: 8px;
  opacity: 1;
  // height: calc(100vh - 160px);
  // overflow: scroll;
  width: 100%;
  margin-left: -10px;
  padding: 20px;
}

.textAlignLeft {
  text-align: left !important;
}

.textAlignCenter {
  text-align: center !important;
}


.textAlignEnd {
  text-align: end !important;
}

.textAlignJustify {
  text-align: justify !important;
}

.justifyCenter {
  justify-content: center !important;
}

.floatLeft {
  float: left !important;
}

.flexLeft {
  display: flex;
  justify-content: left;
}

.switchFontStyle {
  font: normal normal normal 15px/20px Montserrat;
  color: #52575d;
}

.dialogPaddingBottom {
  padding-bottom: 0 !important;
}

.crossStyle {
  color: linear-gradient(#7165e3, #ff6562) !important;
  margin-bottom: 15px;
  cursor: pointer;
}

.newContractCrossStyle {
  color: linear-gradient(#7165e3, #ff6562) !important;
  cursor: pointer;
  margin-top: -10px;
  margin-right: -20px;
  margin-left: 20px;
}

.textFieldWidth {
  width: 350px;
}

.addContractTextFieldWidth {
  width: 400px;
}

select {
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #aaa;
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
    linear-gradient(to bottom, #ffffff 0%, #f5f4f4 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  max-height: 40px;
  margin-top: 6px;

  &:focus {
    outline: none;
  }

  &.fullwidth {
    min-width: 250px;
  }
}

.selectLable {
  text-align: left;
  font: normal normal normal 17px/20px Montserrat;
  color: #52575d;
  // margin-top: 15px;
}

.contractOptions {
  height: 350px;
  margin-top: 3%;
}

.contractCards {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 20px #52575d4d;
  border-radius: 20px;
  opacity: 1;
  width: 305px;
  height: 150px;
  margin-top: 3%;
  margin-right: 20px;
  cursor: pointer;
}

.contractCards:hover {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #7165e3, #ff6562) border-box;
  border-radius: 20px;
  border: 2px solid transparent;
}

.alignCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contractCardData {
  text-align: center;
  font: normal normal 600 12px/14px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  opacity: 1;
  width: 150px;
}

.selectedContractCard {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, #7165e3, #ff6562) border-box;
  border-radius: 20px;
  border: 2px solid transparent;
}

.contractCardImage {
  height: 70px;
  width: 100px;
  object-fit: contain;
  margin-left: 25px;
  margin-bottom: 10px;
}

.nextButton {
  border-radius: 12px;
  opacity: 1;
  background-color: #7165e3;
  color: white;
  border: none;
  height: 40px;
  width: 180px;
  cursor: pointer;
}

.nextButtonDisabled {
  border-radius: 12px;
  opacity: 1;
  background-color: #cccccc;
  color: #666666;
  border: none;
  height: 40px;
  width: 180px;
  cursor: not-allowed;
}

.nextButtonPosition {
  display: flex;
  float: right;
}

.positionCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcomeBorder {
  background: #ebf2f7 0% 0% no-repeat padding-box;
  opacity: 1;
  height: 4px;
  width: 100%;
  margin-bottom: 10px;
}

.activeContractText {
  color: #52575d;
}

.dialogStyle {
  height: 80%;
  width: 60% !important;
  border-radius: 20px;
}

.toolbarDialogStyle {
  height: 100vh;
  width: 100% !important;
  border-radius: 8px !important;
  margin-top: 0;
}

.addManagerDialogStyle {
  height: 50%;
  width: 40%;
  border-radius: 20px;
}

.updateActiveContractsDialogStyle {
  height: 50%;
  width: 60% !important;
  border-radius: 20px;
}

.noDataDialogStyle {
  width: 70% !important;
}

.noDataDialogBackground {
  background-color: white !important;
  padding: 20px !important;
  border-radius: 4px !important;
}

.outlinedButton {
  background-color: white;
  color: #7165e3;
  border: 1px solid #7165e3;
  height: 40px;
  width: 140px;
  border-radius: 10px;
  margin-bottom: -20px;
  box-shadow: 0px 3px 11px #7165e3;
  cursor: pointer;
}

.newContractOutlinedButton {
  background-color: white;
  color: #7165e3;
  border: 1px solid #7165e3;
  height: 40px;
  width: 180px;
  border-radius: 10px;
  margin-bottom: -20px;
}

.cloneOutlinedButton {
  background-color: white;
  color: #7165e3;
  border: 1px solid #7165e3;
  height: 30px;
  min-width: 80px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}

.buttonStyle {
  background-color: #7165e3;
  color: white;
  border: 1px solid #7165e3;
  height: 40px;
  width: 140px;
  border-radius: 10px;
  margin-bottom: -20px;
  box-shadow: 0px 3px 11px #7165e3;
  cursor: pointer;
}

.newContractButtonStyle {
  background-color: #7165e3;
  color: white;
  border: 1px solid #7165e3;
  height: 40px;
  width: 180px;
  border-radius: 10px;
  margin-bottom: -20px;
}

.cloneButtonStyle {
  background-color: #7165e3;
  color: white;
  border: 1px solid #7165e3;
  height: 30px;
  min-width: 80px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 8px;
}

.otherContractsButton {
  background: #f5f5f8 0% 0% no-repeat padding-box;
  border: 0.4000000059604645px solid #d7d4f7;
  border-radius: 6px;
  height: 40px;
  min-width: 140px;
  padding: 10px 20px;
}

.myActiveContractsButton {
  background: #7165e3 0% 0% no-repeat padding-box;
  border: 0.4000000059604645px solid #d7d4f7;
  color: #fff;
  border-radius: 6px;
  height: 40px;
  min-width: 140px;
  padding: 10px 20px;
}

.timesheetNextButtonStyle {
  background-color: #7165e3;
  color: white;
  border: 1px solid #7165e3;
  height: 35px;
  width: 120px;
  border-radius: 6px;
}

@media screen and (max-width: 1350px) {

  .newContractButtonStyle,
  .newContractOutlinedButton {
    width: 140px;
  }
}

.floatRight {
  float: right;
}

.buttonHeight40 {
  height: 40px !important;
}

.cloneContent {
  text-align: left;
  font: normal normal medium 18px/28px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
}

.cloneDialog {
  height: 40%;
  width: 30% !important;
}

.newCloneDialog {
  height: 40%;
  width: 60% !important;
}

.cloneDialogBackground {
  background-color: white;
  margin: -20px !important;
  padding: 20px 40px;
  border-radius: 10px;
}

.cursorPointer {
  cursor: pointer;
}

.calendarStyle {
  margin: 7px;
}

.newContractFromCloneGrid {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-gap: 20px;
}

.newContractFromCloneGridActiveView {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
}

.reduceTop10 {
  margin-top: -10px;
}

.reduceTop {
  margin-top: -5px;
}

.toStyle {
  letter-spacing: 0px;
  color: #52575d;
}

.durationWidth {
  min-width: 150px;
  max-width: 150px;
}

.weekSelectStyle {
  margin-top: 0px;
  height: 30px;
}

.timeCommitment {
  margin-top: -1px;
  height: 31px;
}

.marginBottom {
  margin-bottom: 10px;
}

.flexWrap {
  flex-wrap: wrap !important;
}

.cloneBlockStyle {
  height: calc(100vh - 80px);
  overflow-y: scroll;
  overflow-x: hidden;
  border-right: 4px solid #ebf2f7;
  margin-top: -10px;
  margin-bottom: -20px;
  padding: 20px;
}

.contractEntityCardStyle {
  background: #ebf2f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  min-height: 80px;
  min-width: 100%;
  cursor: pointer;
}

.completedEntityCardStyle {
  background-color: #d7d4f7;
  color: black;
  font-weight: 600 !important;
  display: grid;
  grid-template-columns: 1fr 20px;
  grid-gap: 10px;
}

.completedIconStyle {
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-top: 18px;
}

.warningIconStyle {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.completedIconTableStyle {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.contractEntityFontStyle {
  text-align: left;
  font: normal normal normal 17px/21px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  width: 80%;
  padding: 20px;
}

.tableHeight {
  height: calc(100vh - 170px);
  overflow: scroll;
}

.selectedContractEntityStyle {
  border: 1px solid #7165e3;
  color: #7165e3;
}

.selectedColor {
  color: #7165e3;
}

.smallHeadingStyle {
  text-align: left;
  font: normal normal 600 17px/16px Montserrat;
  letter-spacing: 0px;
  line-height: 20px;
  color: #7165e3;
  opacity: 1;
}

.descriptionStyle {
  text-align: left;
  font: normal normal normal 15px/28px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #52575d;
}

.deleteDescriptionStyle {
  text-align: left;
  font: normal normal normal 13px/23px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #52575d;
  line-height: 20px;
}

.descriptionBoxStyle {
  // border: 1px solid #b3b8bd;
  border-radius: 8px;
  opacity: 1;
  width: 660px;
  margin-top: 10px;
  padding: 30px 20px;
  background-color: white;
}

.multipleSvgMarginLeft {
  margin-left: 20%;
}

.individualSvgMarginLeft {
  margin-left: 25%;
}

.roleBoxStyle {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #52575d4d;
  border-radius: 6px;
  opacity: 1;
  padding: 20px;
  width: 100%;
  display: block;
}

.blueColor {
  color: #7165e3;
}

.selectFieldWidth {
  min-width: 260px;
  max-width: 260px;
}

.selectAssignedContractFieldWidth {
  width: 100%;
}

.selectFieldSwitchWidth {
  min-width: 210px;
  max-width: 210px;
}

.fieldWidth2InARow {
  width: 50%;
}

.minMaxLeftElement {
  background-color: #f1f2f3;
  color: #afb2b6;
  border: none;
  height: 30px;
  font-size: 12px;
  width: 80px;
}

.dollarLeftElement {
  color: #afb2b6;
  background-color: #fff;
  border: none;
  margin: 2px 2px;
  height: 25px;
  font-size: 12px;
  width: 20px;
}

.textElement {
  background-color: #f1f2f3;
  color: #afb2b6;
  border: none;
  height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 20px;
  line-height: 15px;
  width: 60%;
}

.textElementMinimized {
  background-color: #f1f2f3;
  color: #afb2b6;
  border: none;
  height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 0px;
  line-height: 15px;
  width: 30%;
}

.greenBase {
  font: normal normal medium 20px/27px Montserrat;
  background-color: #8cf2ba;
  color: #52575d;
}

.redBase {
  font: normal normal medium 20px/27px Montserrat;
  background-color: #fc9c9c;
  color: #52575d;
}

.textElementWithNurse {
  background-color: #f1f2f3;
  color: #afb2b6;
  border: none;
  height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  line-height: 15px;
  width: 60%;
}

.serviceProvidedEditableTextStyle {
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  // padding-top: 8px;
  width: 40%;
  border-radius: 4px !important;
}

.editableTextThresholdStyle {
  height: 35px;
  // display: flex;
  // align-items: center;
  text-align: left;
  padding-left: 15px;
  padding-top: 8px;
  width: 60px;
  border-radius: 4px !important;
}

.editableTextStyleDays {
  height: 35px;
  display: flex;
  align-items: center;
  text-align: left;
  // padding-top: 8px;
  padding-left: 10px;
  width: 50px;
  border-radius: 4px !important;
}

.textElementWithoutBackgroundDays {
  color: #afb2b6;
  border: none;
  height: 35px;
  font-size: 12px;
  display: flex;
  align-items: center;
  line-height: 15px;
  width: 60%;
}

.editableSessionTextStyle {
  height: 35px;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  width: 40% !important;
  border-radius: 4px !important;
}

.editableTextOuterBorder {
  height: 37px;
  border: 1px solid #e9e9f0 !important;
  border-radius: 4px;
  min-width: 33.3% !important;
}

.editableTextOuterBorderSmall {
  height: 37px;
  border: 1px solid #e9e9f0 !important;
  border-radius: 4px;
  min-width: 27% !important;
}

.leftAlign {
  text-align: left;
}

.threeFieldWidth {
  min-width: 33.3% !important;
}

.minMaxFieldWidth {
  min-width: 35%;
}

.fourFieldWidth {
  width: 25%;
}

.twoFieldWidth {
  width: 50%;
}

.differentYearAmountGrid {
  display: grid;
  grid-template-columns: 1.2fr .8fr;
  grid-gap: 10px;
}

.patientSurgeGrid {
  display: grid;
  grid-template-columns: 1fr .6fr .4fr;
  grid-gap: 10px;
}

.renewalWidth {
  width: 100px;
  height: 100%;
}

.plusOneText {
  text-align: left;
  font: normal normal medium 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #aeb2b7;
}

.validationAlert {
  background: #ffcdcc 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 10px 10px 0 10px;
  color: black !important;
}

.blackText {
  color: black !important;
}

.bold {
  font-weight: bold;
}

.documentCard {
  background-color: #ebe9fb;
  padding: 10px 5px 5px 5px !important;
  border-radius: 6px;
  border: 1px solid #d7d4f7;
}

.documentGrid {
  display: grid;
  grid-template-columns: 15% 1fr;
}

.documentHITGrid {
  display: grid;
  grid-template-columns: 15% 1fr 5%;
}

.documentTextGrid {
  display: grid;
  grid-template-columns: 85% 10%;
  grid-gap: 10px;
}

.documentText {
  color: #707070;
  word-wrap: break-word;
  width: 150px;
}

.documentTextActive {
  color: #707070;
  word-wrap: break-word;
  width: 100%;
}

.proofBorder {
  border: 1px solid #7165e3;
  border-radius: 8px;
  overflow: scroll;
  margin-top: 10px;
  padding: 20px;
}

.addProofDialog {
  height: 75%;
  width: 55% !important;
}

.manageServiceDialog {
  height: 75%;
  width: 75% !important;
}

.bp4-timepicker.reducedTimeWidth .bp4-timepicker-input {
  width: 20px !important;
}

// .bp4-timepicker.reducedTimeWidth {
//     & .bp4-timepicker-input {
//         width: 20px !important;
//     }
// }

.ScheduleDialog {
  width: 70% !important;
}

.moveDialogPosition {
  margin-left: -20% !important;
}

.renewalBoxStyle {
  background-color: #ebeced;
  color: #52575d;
  text-align: left;
  border: 1px solid #b3b8bd;
  border-radius: 10px;
  height: 100px;
  font: normal normal normal 13px/15px Montserrat;
  margin-top: 10px;
  padding: 15px;
}

.departmentLevelBoxStyle {
  background-color: #ebeced;
  color: #52575d;
  text-align: left;
  border: 1px solid #b3b8bd;
  border-radius: 10px;
  // height: 140px;
  font: normal normal normal 13px/15px Montserrat;
  margin-top: 10px;
  padding: 15px;
}

.siteLevelBoxStyle {
  background-color: #ebeced;
  color: #52575d;
  text-align: left;
  border: 1px solid #b3b8bd;
  border-radius: 10px;
  // height: 100px;
  font: normal normal normal 13px/15px Montserrat;
  margin-top: 10px;
  padding: 15px;
}

.renewalRemainderBoxStyle {
  color: #52575d;
  text-align: left;
  border: 1px solid #b3b8bd;
  border-radius: 10px;
  font: normal normal normal 13px/15px Montserrat;
  margin-top: 10px;
  padding: 15px;
}

.inputRenewalStyle {
  background: #ebeced !important;
  border: 1px solid #b3b8bd;
  height: 30px !important;
  border-radius: 4px;
  padding: 7px 0 0 25px;
  color: black;
}

.inputRenewalRemainderStyle {
  background: #ebeced !important;
  border: 1px solid #b3b8bd;
  height: 30px !important;
  border-radius: 4px;
  padding: 7px 0 0 25px;
  color: black;
  width: 50%;
}

.renewalBoxGrid {
  display: grid;
  grid-template-columns: 45% 20% 35%;
}

.siteLevelGrid {
  display: grid;
  grid-template-columns: 35% 65%;
}

.renewalRemainderBoxGrid {
  display: grid;
  grid-template-columns: 50% 40% 10%;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.surgeBurdenGrid {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1.5fr;
  grid-gap: 20px;
}

.grid3WithoutGap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.withNurseGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 20px;
}

.twoCol {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.sendMailUserDialog {
  height: 40%;
  width: 40% !important;
}

.padding10 {
  padding: 10px;
}

.padding20 {
  padding: 20px;
}

.searchIcon {
  max-height: 20px;
  max-width: 20px;
  margin-left: 20px;
  margin-top: 7px;
}

.userMailListGrid {
  display: grid;
  grid-template-columns: 20px 50px 1fr 20px;
}


.userLogoMailStyle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: contain;
}

.mailIdTextColor {
  color: #52575d;
}

.mailTemplate {
  height: 80% !important;
  width: 75% !important;
}

.sendNotificationsButtonWidth {
  width: 200px;
}

.mailLeftElement {
  margin-top: 6px;
  margin-left: 10px;
}

.redFont {
  color: red;
}

.envelopeImageStyle {
  height: 60px;
  width: 60px;
}

.sendMailConfirmationStyle {
  text-align: center;
  font: normal normal normal 13px/23px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #52575d;
  line-height: 20px;
  width: 100%;
  padding: 0 50px;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
  display: none !important;
  background: transparent !important;
}

.contractedBorderStyle {
  border: 0.800000011920929px solid #e5e6e7;
  border-radius: 8px;
  margin: -10px;
  padding: 10px;
}

.timesheetButtonStyle {
  background: #ebf2f7 0% 0% no-repeat padding-box;
  border: 1px solid #7165e3;
  border-radius: 6px 6px 0px 0px;
  min-width: 180px;
  height: 40px;
}

.selectedTimesheetButton {
  background: #d7d4f7 0% 0% no-repeat padding-box;
}

.timeSheetBoxStyle {
  border: 1px solid #7165e3;
  border-radius: 0px 6px 0px 0px;
  opacity: 1;
  height: calc(100vh - 160px);
  overflow: scroll;
  width: 100%;
  margin-left: -10px;
  padding: 20px;
}

.timeSheetBoxStyleForUpdateActiveContract {
  border: 1px solid #7165e3;
  border-radius: 0px 6px 0px 0px;
  width: 100%;
  margin-left: -10px;
  padding: 20px;
}

.timeSheetBoxStyleModify {
  border: 1px solid #7165e3;
  border-radius: 0px 6px 0px 0px;
  width: 100%;
  padding: 20px;
}

.purpleBorder {
  border: 1px solid #7165e3;
}

.serviceProviderModifyTableStyle {
  border-radius: 6px;
  padding: 15px;
}

.verticalSpaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flowChartBoxStyle {
  border: 1px solid #f3f3f3;
  opacity: 1;
  height: 200vh;
  width: 100%;
  background-color: #f3f3f3;
}

.disabledView {
  opacity: 0.5;
}

.tagStyle {
  margin: 0 5px 10px 0 !important;
}

input[type="file"] {
  display: none;
}

.customFileUpload {
  cursor: pointer;
  width: 70px;
  height: 50px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.25;
  background-color: #f1f2f3;
  color: #7165e3;
  border: none;
  position: relative;
  top: 7px;
}

.addButtonPosition {
  display: flex;
  justify-content: flex-end;
}

.horizontalScroll {
  width: 30em;
  overflow-x: auto;
  white-space: nowrap;
}

.verticalAlignCenter {
  display: flex;
  align-items: center;
  height: 100%;
}

.pageAlignCenter {
  // display: flex;
  text-align: center;
  height: 100%;
}

.purpleTitle {
  font: normal 15px/13px Montserrat;
  color: #7165e3;
  font-size: 15px;
  text-align: left;
  min-width: 100vw;
}

.marginTop50 {
  margin-top: 50px;
}

.marginTop80 {
  margin-top: 80px;
}

.popUpHeading {
  font: bold 18px/25px Montserrat;
  color: #7165e3;
}

.popUpHeaderBlock {
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  background-color: #dbd8fa;
  border: 3px solid #ebf2f7;
  border-radius: 2px;
  opacity: 1;
  padding: 10px;
}

.popUpHeaderBlockActive {
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 3fr;
  grid-gap: 20px;
  background-color: #DBD8FA;
  border: 3px solid #EBF2F7;
  border-radius: 2px;
  opacity: 1;
  padding: 10px;
}

.addServiceDialog {
  height: 75%;
  width: 85% !important;
}

.contractViewDialog {
  height: 100%;
  width: 100% !important;
}

.addGrid {
  display: grid;
  grid-template-columns: 1fr 35px;
  grid-gap: 10px;
}

.onCallBillableGrid {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 35px;
  grid-gap: 10px;
}

.addStyle {
  background: #7165e3 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  height: 33px;
  min-width: 35px;
}

.addSmallStyle {
  background: #7165e3 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  height: 23px;
  min-width: 25px;
}

.addonBoxStyle {
  border: 0.800000011920929px solid #e5e6e7;
  border-radius: 8px;
  padding: 20px;
}

.addAddonServiceButton {
  background: #7165e3 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 6px;
  font: normal normal medium 20px/27px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 6px 20px;
}

.addCptCodeButton {
  background: #7165e3 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 6px;
  font: normal normal bolder 14px/18px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  padding: 6px 15px;
  height: 38px;
  opacity: 0.5;
  cursor: not-allowed;
  text-align: center;
}

.addAddonGrid {
  display: grid;
  grid-template-columns: 1fr 240px;
  grid-gap: 10px;
}

.addonAddBox {
  border: 1px solid #7165e3;
  box-shadow: 0px 1px 6px #7165e3;
  border-radius: 8px;
  padding: 20px 10px;
}

.additionalDetails {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001f;
  border: 1px solid #e9e9f0;
  border-radius: 4px;
  height: 45px;
  width: 100%;
  display: grid;
  grid-template-columns: 50px 1fr;
}

.additionalDetailsSelected {
  background: #dbd8fa 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #0000001f;
  border: 0.30000001192092896px solid #7165e3;
}

.additionalDetailsTextStyle {
  font: normal normal medium 18px/24px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  text-transform: capitalize;
}

.chipStyle {
  border-radius: 23px;
  padding: 5px 10px;
  margin-right: 10px;
  max-height: 30px;
}

.greenChip {
  background: #a3f5c8 0% 0% no-repeat padding-box;
  color: #14b15a;
}

.orangeChip {
  background: orange 0% 0% no-repeat padding-box;
  color: orangered;
}

.yellowChip {
  background: lemonchiffon 0% 0% no-repeat padding-box;
  color: orange;
}

.blueChip {
  background: #dbd8fa 0% 0% no-repeat padding-box;
  color: #7165e3;
}

.redChip {
  background: #f6baa2 0% 0% no-repeat padding-box;
  color: #ff6f3b;
}

.administrativePopoverStyle {
  margin: 20px;
}

.administrativeCardStyle {
  height: 40px;
}

.selectedAdministrativeCardStyle {
  background-color: #ebe9fb;
  margin: 0 -20px;
  padding: 0 20px;
}

.dialogAdditionalDetailTextStyle {
  text-align: left;
  font: normal normal medium 18px/22px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
}

.dialogAdditionalDetailBoxStyle {
  background: #dbd8fa 0% 0% no-repeat padding-box;
  border: 2px solid #ebf2f7;
  border-radius: 2px;
  padding: 15px;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 20px;
  margin-top: 5px;
}

.helperText {
  text-align: left;
  font: normal normal medium 13px/18px Montserrat;
  letter-spacing: 0px;
  color: #7165e3;
}

.disabledOpacity {
  opacity: 0.3;
}

.actionsCard {
  background: #857aef 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 4px;
}

.specificActionCard {
  height: 35px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  color: #f5f9fd;
}

.specificActionCard:hover {
  background: #f5f9fc 0% 0% no-repeat padding-box;
  color: #7165e3;
  border: 1px solid #7165e3;
  border-radius: 4px;
}

.lockStyle {
  font-size: 20px;
}

.contractedTimeCommitment {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.businessContractManagerRoleInfo {
  background: #dbd8fa 0% 0% no-repeat padding-box;
  padding: 10px;
  border-radius: 4px;
  font: normal normal 300 12px/15px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  text-align: left;
}

.activationDescriptionBoxStyle {
  text-align: left;
  font: normal normal medium 13px/22px Montserrat;
  color: #b3b8bd;
  border: 1px solid #c4bef3;
  border-radius: 8px;
  padding: 10px;
}

.selectBoxStyle {
  border: 1px solid #e9e9f0;
  border-radius: 4px;
  height: 35px;
}

.arrowDownStyle {
  height: 10px;
  width: 15px;
  object-fit: contain;
}

.selectOptionsBox {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #52575d4d;
  border-radius: 0px 0px 6px 6px;
}

.selectOptionsMenuStyle {
  border-bottom: 1px solid #e9e9f0;
  padding: 0px 10px;
}

.selectedOptionstyle {
  background: #ebe9fb 0% 0% no-repeat padding-box;
}

.contractScrollStyle {
  height: calc(100vh - 420px);
  overflow: scroll;
}

.greenBigNumber {
  color: #14b15a !important;
}

.yellowBigNumber {
  color: #fec106 !important;
}

.redBigNumber {
  color: #f94848 !important;
}

.greenSmallNumber {
  color: #14b15a;
  background-color: #d1fae3;
}

.greenSmallNumberSelected {
  color: #fff;
  background-color: #14b15a;
}

.yellowSmallNumber {
  color: #fec106;
  background-color: #fff2cc;
}

.yellowSmallNumberSelected {
  color: #fff;
  background-color: #fec106;
}

.redSmallNumber {
  color: #f94848;
  background-color: #fdcece;
}

.redSmallNumberSelected {
  color: #fff;
  background-color: #f94848;
}

.purpleText {
  color: #e316d6;
}

.popUpPreImplementationHeading {
  font: bold 15px/15px Montserrat;
  color: #7165e3;
}

.popUpPreImplementationSubHeading {
  font: bold 13px/15px Montserrat;
  color: #52575d;
}

.popUpPreImplementationTitle {
  font: bold 13px/15px Montserrat;
  color: #7165e3;
}

.preImplementationGrid {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-gap: 1%;
}

.preImplementationHeaderGrid {
  display: grid;
  grid-template-columns: 75% 20% 5%;
  grid-gap: 1%;
}

.preImplementationDataFrequency {
  max-width: 150px;
}

.preImplementationPadding {
  padding: 0 20px;
}

.leftBorder {
  border-left: 3px solid #e9e9f0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.paymentTimesheetDetailsHeading {
  text-align: left;
  font: normal normal normal 14px/14px Montserrat;
  letter-spacing: 0px;
  color: #7165e3;
  text-transform: uppercase;
}

.helperTextPayment {
  text-align: center;
  font: normal normal normal 12px/12px Montserrat;
  letter-spacing: 0px;
  color: #7165e3;
  text-transform: capitalize;
}

.pdfViewStyle {
  margin: 0 -55px;
}

.siteDeptFieldCard {
  background: #dbd8fa 0% 0% no-repeat padding-box;
  border: 1px solid #e9e9f0;
  border-radius: 4px;
  width: 100%;
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}

.deptCard {
  background: #f6f5f5 0% 0% no-repeat padding-box;
  border-radius: 3px;
  min-height: 23px;
  min-width: 70px;
  padding: 5px;
}

.contractFilterCard {
  background: #E8E7FD 0% 0% no-repeat padding-box;
  border-radius: 10px;
  min-height: 15px;
  min-width: 70px;
  padding: 3px;
}

.contractFiltersHeading {
  font: normal normal normal 13px/16px Montserrat;
  color: #525762;
}

.contractFiltersTextStyle {
  text-align: left;
  font: normal normal medium 10px/12px Montserrat;
  color: #52575d;
}

.siteDeptTextStyle {
  text-align: left;
  font: normal normal medium 15px/17px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
}

.siteDeptCrossStyle {
  color: #707070 !important;
}

.validationBoxHeader {
  background: #f5f6f6 0% 0% no-repeat padding-box;
  height: 45px;
  width: 100%;
  padding: 0 15px 0 25px;
}

.validationHeaderText {
  text-align: left;
  font: normal normal normal 16px/20px Montserrat;
  letter-spacing: 0px;
  color: #18191b;
  text-transform: uppercase;
}

.redBorder {
  border: 0.6000000238418579px solid #f54646;
}

.redBorderInput {
  border: 0.6000000238418579px solid #f54646;
  border-radius: 4px;
  margin-bottom: 10px;
}

.redLable {
  color: #f54646 !important;
}

.validationTopicText {
  text-align: left;
  font: normal normal normal 15px/20px Montserrat;
  letter-spacing: 0px;
  color: #94979a;
}

.statusText {
  text-align: left;
  font: normal normal normal 15px/20px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.validationPadding {
  padding: 20px 60px;
}

.documentationProofGrid {
  display: grid;
  grid-template-columns: 70% 15% 15%;
  grid-gap: 10px;
}

.footerIconStyle {
  height: 20px;
  width: 100px;
  object-fit: contain;
  margin-top: 5px;
}

.multipleServiceProviderGrid {
  display: grid;
  grid-template-columns: 8% 20% 20% 20% 18% 7%;
  grid-gap: 1%;
  padding-left: 3%;
}

.downloadImage {
  width: 30px;
  height: 30px;
}

.businessEntityGrid {
  display: grid;
  grid-template-columns: 15% 60% 20%;
  grid-gap: 1%;
  padding-left: 3%;
}

.serviceSpecificationGrid {
  display: grid;
  grid-template-columns: 8% 22% 22% 19% 10% 6% 6%;
  grid-gap: 1%;
  padding-left: 10px;
}

.twoColumnGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.serviceSpecificationGridActive {
  display: grid;
  grid-template-columns: 8% 22% 22% 19% 10% 6% 6%;
  grid-gap: 1%;
  padding-left: 10px;
}

.serviceSpecificationGridIndividual {
  display: grid;
  grid-template-columns: 10% 30% 25% 13% 8% 8%;
  grid-gap: 1%;
  padding-left: 10px;
}

.serviceSpecificationGridIndividualActive {
  display: grid;
  grid-template-columns: 10% 30% 25% 13% 8% 8%;
  grid-gap: 1%;
  padding-left: 10px;
}

.calculatorDisplayStyle {
  position: absolute;
  right: 20px;
  top: 50px;
}

.addServiceImgStyle {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.addServiceNotesImgStyle {
  height: 30px;
  width: 30px;
  object-fit: contain;
}

.addServiceCountStyle {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7165e3;
  height: 15px;
  width: 15px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  color: red;
  font-size: 10px;
  display: flex;
  flex-direction: row-reverse;
}

.marginNotes {
  margin-top: -20px;
  margin-left: 25px;
}

.marginDocument {
  margin-top: -15px;
  margin-left: 18px;
}

.tableHeader {
  background: #beb9f2 0% 0% no-repeat padding-box;
  min-height: 50px;
  width: 100%;
  border-radius: 6px;
  padding: 0 10px;
}

.offsetTableHeader {
  background: #beb9f2;
  min-height: 50px;
  width: 100%;
  border-radius: 6px;
  // padding-top: 10px;
  padding-left: 10px;
}

.editableTableGridStyle {
  display: grid;
  grid-template-columns: 15% 18% 18% 18% 10% 10% 5%;
  grid-gap: 1%;
}

.offsetRuleGridStyle {
  display: grid;
  grid-template-columns: 25% 50% 10%;
  grid-gap: 5%;
}

.tableHeaderFontStyle {
  font: normal normal bold 13px/15px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  text-transform: uppercase;
  opacity: 1;
  text-align: left;
}

.tableData {
  background: #f5f5f8 0% 0% no-repeat padding-box;
  min-height: 50px;
  width: 100%;
  border-radius: 6px;
  margin-top: 5px;
  padding: 0 10px;
}

.tableDataFontStyle {
  font: normal normal normal 13px/17px Montserrat;
  letter-spacing: 0px;
  color: #52575d;
  text-transform: uppercase;
  text-align: left;
}

// .scheduleTableGrid1 {
//     display: grid;
//     grid-template-columns: 2fr 1fr 1fr 2fr 1fr 2fr 1fr;
// }

.scheduleTableGrid2 {
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 0fr 0fr;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.scheduleTableGrid1 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 2fr 1fr 2fr 1fr;
}

.scheduleTableGrid2 {
  display: grid;
  grid-template-columns: 2fr 0.5fr 0.5fr 1fr 1fr 1fr 1fr 1.5fr 1.5fr 0.5fr;
}

.dayStyle {
  background: #f1f3f4 0% 0% no-repeat padding-box;
  font: normal normal 600 13px/20px Montserrat;
  color: #94979a;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 8px;
}

.selectedDay {
  background: #7165e3 0% 0% no-repeat padding-box;
  color: white;
}

.dateNotSet {
  font: normal normal 600 23px/30px Montserrat;
  color: #7165e3;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.addContractBody.serviceBoxStyle.cloneBlockStyle.tableHeight.proofBorder.timeSheetBoxStyle.contractScrollStyle::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.addContractBody.serviceBoxStyle.cloneBlockStyle.tableHeight.proofBorder.timeSheetBoxStyle.contractScrollStyle {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.fileNameTextStyle {
  text-align: left;
  font: normal normal normal 18px/24px Montserrat;
  letter-spacing: 1.44px;
  color: #707070;
  opacity: 0.71;
  word-break: break-word;
  width: auto;
}

.moveToDraftBox {
  height: 20vh;
  width: 40vw;
  background-color: #f1f1f1;
  border-radius: 0 0 6px 6px;
}

.fullHeight {
  height: 100vh;
}

.moveToDraftHeaderBox {
  height: 80px;
  width: 100%;
  background-color: #7165e3;
  border-radius: 6px 6px 0 0;
}

.moveToDraftHeading {
  text-align: center;
  font-family: Montserrat;
  font-size: 25px;
  font-weight: bolder;
  letter-spacing: 0px;
  color: #fff;
}

.warningIconStyle {
  height: 25px;
  width: 25px;
  object-fit: contain;
}

.warningTextAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.marginLeft5 {
  margin-left: 5px !important;
}

.currencyFormatInput {
  font-size: 15px;
  padding: 10px 0 10px 5px;
  border: 1px solid #E9E9F0;
  border-radius: 4px;
  box-sizing: content-box;
  height: 15px;
  width: 100%;
}

.trackTableBackgroudcard {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding: 60px 0 60px 0;
  width: calc(80vw - 65px);
}

.trackServiceProviderName {
  text-align: left;
  font: normal normal 600 15px/23px Montserrat;
  letter-spacing: 0px;
  color: #7165E3;
  text-transform: uppercase;
}

.trackTableContractCountHeading {
  text-align: left;
  font: normal normal normal 12px/23px Montserrat;
  letter-spacing: 0px;
  color: #7165E3;
  text-transform: capitalize;
  opacity: 0.88;
}

.trackTableContractCount {
  background-color: #e6fff6;
  border-radius: 10px;
  height: 25px;
  width: 35px;
  color: #00C07E;
}

.trackTableContractLabel {
  text-align: left;
  font: normal normal 600 12px/19px Montserrat;
  letter-spacing: 0px;
  color: #616161;
  text-transform: capitalize;
}

.trackPeriodCard {
  background: #F1EFFC 0% 0% no-repeat padding-box;
  border: 1px solid #7165E3;
  border-radius: 8px;
  height: 60px;
}

.trackPeriodOptionsCard {
  background: #F1EFFC 0% 0% no-repeat padding-box;
  height: 40px;
  border: 1px solid #7165E3;
  border-top: 0;
  padding-left: 20px;
}

.trackPeriodOptionsCard:hover {
  background: #F1EFFC 0% 0% no-repeat padding-box;
  border: 1px solid #7165E3;
}

// .intervalPeriodBorder {
//   border: 1px solid #7165E3;
//   border-top: 0px;
//   border-bottom: 0px;
//   border-radius: 8px;
// }


.trackContractOrAgreementCount {
  font: normal normal medium 14px/14px Montserrat;
  letter-spacing: 0px;
  color: #94979A;
}

.trackContractUserAndPeriod {
  font: normal normal bold 18px/20px Montserrat;
  color: #52575D;
}

.timesheetIntervalListText {
  font: normal normal 600 14px/20px Montserrat;
  color: #52575D;
}

.trackTableHeaderGrid {
  display: grid;
  grid-template-columns: .9fr .7fr .4fr;
}

.timesheetTableHeaderMiddleGrid {
  display: grid;
  grid-template-columns: 20% 15% 15% 21% 21% 8%;
}

.timesheetTableHeaderBottomGrid {
  display: grid;
  grid-template-columns: 20% 15% 15% 14% 7% 14% 7% 8%;
}

.timesheetTableDataGrid {
  display: grid;
  grid-template-columns: 20% 15% 15% 14% 7% 14% 7% 8%;
}

.paymentPendingTableDataGrid {
  display: grid;
  grid-template-columns: 25% 25% 20% 15% 15%;
}

.submissionPendingTableDataGrid {
  display: grid;
  grid-template-columns: 33% 33% 33%;
}

.approvalPendingTableDataGrid {
  display: grid;
  grid-template-columns: 25% 25% 20% 15% 15%;
}

.paymentProcessedTableDataGrid {
  display: grid;
  grid-template-columns: 15% 15% 13% 10% 13% 10% 13% 10%;
}


.trackTableHeaderMiddleGrid {
  display: grid;
  grid-template-columns: 47.5% 7.5% 15% 15% 15%;
}

.trackTableHeaderMiddleGridForActivityBased {
  display: grid;
  grid-template-columns: 60% 20% 20%;
}

.trackTableHeaderTopGrid {
  display: grid;
  grid-template-columns: 47.5% 52.5%;
}

.trackTableHeaderTopGridForActivityBased {
  display: grid;
  grid-template-columns: 60% 40%;
}

.trackTableHeaderBottomGrid {
  display: grid;
  grid-template-columns: 47.5% 7.5% 7.5% 7.5% 7.5% 7.5% 7.5% 7.5%;
}

.trackTableHeaderBottomGridForActivityBased {
  display: grid;
  grid-template-columns: 60% 10% 10% 10% 10%;
}

.trackTableDataGrid {
  display: grid;
  grid-template-columns: 47.5% 7.5% 7.5% 7.5% 7.5% 7.5% 7.5% 7.5%;
}

.trackTableDataGridForActivityBased {
  display: grid;
  grid-template-columns: 60% 10% 10% 10% 10%;
}

.whiteBackground {
  background-color: white;
}

.muiDataGridWithBorderColor {
  border-color: #646D82;
}

.arrowDown {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #52575D;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.arrowUp {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #52575D;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}

.fileFormatStyle {
  font-size: 12px;
  font-style: italic;
  color: #f14040;
}

.iconSize {
  height: 30px;
  width: 30px;
}

.paymentTabStyle {
  text-align: center;
  font: normal normal 600 15px/23px Montserrat;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #A4A4A4;
  border: 1px solid #52575D;
  height: 45px;
  cursor: pointer;
}

.selectedPaymentTabStyle {
  color: #7165E3;
  border: 1px solid #7165E3;
  background-color: #F1EFFC;
}

.paymentTabGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.doNotDisturbTextStyle {
  font: normal normal 600 12px/15px Montserrat;
  letter-spacing: 2.24px;
  color: #F46044;
  text-transform: capitalize;
}

.confidentialTextStyle {
  font: normal normal bold 17px/20px Montserrat;
  letter-spacing: 1.05px;
  color: #F46044;
}

.confidentialBoxStyle {
  border: 2px solid #F46044;
  border-radius: 7px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.filterGrid {
  display: flex;
  flex-wrap: wrap;
}

.upperCase {
  text-transform: uppercase;
}

.hidePrintableContainer {
  display: none;
}

@media print {
  .hidePrintableContainer {
    display: block;
  }

  @page {
    size: landscape;
    margin: 0 0 1cm 0;
  }

  .page {
    page-break-after: always;
  }

  .printFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 12px;
  }

  .page-number:after {
    content: counter(page);
  }
}