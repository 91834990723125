.deleteEcecutedContractDialogBackground {
    background-color: white;
    margin: -20px !important;
    padding: 20px;
    border-radius: 10px;
}

.cloneDialog {
    height: 40%;
    width: 30% !important;
}

.dialogPaddingBottom {
    padding-bottom: 0 !important;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.extensionStyle {
    text-align: left;
    font: normal normal bold 15px/13px Montserrat;
    letter-spacing: 0px;
    color: #7165E3;
}


.crossStyle {
    color: linear-gradient(#7165E3, #FF6562) !important;
    margin-bottom: 15px;
    cursor: pointer;
}

.extensionBorder {
    background-color: #EBF2F7;
    height: 3px;
}

.marginTop20 {
    margin-top: 20px !important;
}

.deleteDescriptionStyle {
    text-align: left;
    font: normal normal normal 13px/23px Montserrat;
    letter-spacing: 0px;
    opacity: 1;
    color: #52575D;
    line-height: 20px;
}

.blueColor {
    color: #7165E3;
}

.marginLeft20 {
    margin-left: 10px;
}

.positionCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cloneButtonStyle {
    background-color: #7165E3;
    color: white;
    border: 1px solid #7165E3;
    height: 30px;
    min-width: 80px;
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 8px;
}

.cursorPointer {
    cursor: pointer;
}