.siteDeptFieldCard {
    background: #DBD8FA 0% 0% no-repeat padding-box;
    border: 1px solid #E9E9F0;
    border-radius: 4px;
    width: 100%;
    padding: 5px 10px;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
}

.siteDeptGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.twoCol {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}

.serviceDaysGrid {
    display: grid;
    grid-template-columns: 1fr 0.6fr 0.6fr;
    grid-gap: 10px;
}

.siteCard {
    background: #F6F5F5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    max-height: 23px;
    min-width: 70px;
    padding: 5px;
}

.deptCard {
    background: #F6F5F5 0% 0% no-repeat padding-box;
    border-radius: 3px;
    min-height: 23px;
    min-width: 70px;
    padding: 5px;
}

.displayInRow {
    display: flex;
    flex-direction: row;
}

.siteDeptTextStyle {
    text-align: left;
    font: normal normal medium 15px/17px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
}

.alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.verticalAlignCenter {
    display: flex;
    align-items: center;
    height: 100%;
}

.marginLeft10 {
    margin-left: 10px !important;
}

.marginRight10 {
    margin-right: 10px !important;
}

.marginRight5 {
    margin-right: 5px !important;
}

.marginTop40 {
    margin-top: 40px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginTop10 {
    margin-top: 10px;
}

.siteDeptCrossStyle {
    color: #707070 !important;
}

.cursorPointer {
    cursor: pointer;
}

.addStyle {
    background: #7165E3 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
}

.dayStyle {
    background: #F1F3F4 0% 0% no-repeat padding-box;
    font: normal normal 600 13px/20px Montserrat;
    color: #94979A;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 8px;
}

.selectedDay {
    background: #7165E3 0% 0% no-repeat padding-box;
    color: white;
}

.greenBase {
    background-color: #75f0ac;
    color: #52575D;
    font: normal normal medium 20px/27px Montserrat;
    height: 35px;
    font-size: 12px;
    padding: 0 20px;
    line-height: 15px;
}

.totalTextStyle {
    font: normal normal medium 14px/15px Montserrat;
    letter-spacing: 0px;
    color: #7165E3;
    text-align: center;
}

.daysWarningText {
    background: #FAE2E2 0% 0% no-repeat padding-box;
    border: 2px solid #EBF2F7;
    border-radius: 2px;
    font: normal normal normal 14px/16px Montserrat;
    letter-spacing: 0px;
    color: #F94848;
    padding: 5px 15px;
}

.noContractsBox {
    border: 2px dashed #c4bef3;
    padding: 20px;
    margin: 30px 20px;
    height: 170px;
}

.noReportsBox {
    border: 4px solid #7165E3;
    padding: 60px;
    margin: 50px 20px;
}

.noContractsFontStyle {
    font: normal normal bolder 28px/35px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
    text-align: center;
}

.noContractsSmallFontStyle {
    font: normal normal normal 20px/25px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
    text-align: center;
}

.linkStyle {
    text-decoration: underline;
    color: #8080ff;
}

.justifyCenter {
    display: flex;
    justify-content: center !important;
}
