.tableHeader {
    background: #BEB9F2 0% 0% no-repeat padding-box;
    min-height: 50px;
    width: 96%;
    margin-left: 2%;
    border-radius: 6px;
}

.marginTop10 {
    margin-top: 10px !important;
}

.marginTop20 {
    margin-top: 20px !important;
}

.marginTop40 {
    margin-top: 40px !important;
}

.marginLeft5 {
    margin-left: 5px !important;
}

.marginLeft30 {
    margin-left: 30px;
}

.tableHeaderFontStyle {
    font: normal normal bold 13px/17px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
    text-transform: uppercase;
    opacity: 1;
    text-align: left;
    word-break: break-word;
    width: auto;
}

.tableData {
    background: #F5F5F8 0% 0% no-repeat padding-box;
    min-height: 50px;
    width: 96%;
    margin-left: 2%;
    border-radius: 6px;
    margin-top: 5px;
}

.alternativeBackgroundColor {
    background-color: #ebe9fb !important;
}

.displayInRow {
    display: flex;
    flex-direction: row;
}

.green {
    color: #00C07F;
}

.yellow {
    color: #FEC106;
}

.red {
    color: #FF6562;
}

.grey {
    color: #D0D0EC;
}

.greenDotStyle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #00C07F;
}

.purpleDotStyle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #948BEA;
}

.yellowDotStyle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #FEC106;
}

.redDotStyle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #FF6562;
}

.greyDotStyle {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #D0D0EC;
}

.tableDataFontStyle {
    font: normal normal normal 13px/17px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
    text-transform: uppercase;
    text-align: left;
    word-break: break-word;
    width: auto;
}

.cursorPointer {
    cursor: pointer;
}

.colorFileStyle {
    height: 15px !important;
    width: 15px !important;
    margin-right: 5px;
    object-fit: contain;
}

.dotStyle {
    height: 20px;
    width: 80px;
    object-fit: contain;
}

.displayInCol {
    display: flex;
    flex-direction: column;
}

.actionCard {
    background: #D8D7EE 0% 0% no-repeat padding-box;
    opacity: 0.99;
    width: 40px;
}

.actionsIcon {
    width: 30px;
    height: 30px;
    margin: 5px;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.paginationStyle {
    font: normal normal 600 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
    opacity: 1;
    margin-top: 10px;
}

.marginLeft20 {
    margin-left: 10px;
}

.leftChevronColor {
    color: #52575D;
}

.roundChevron {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 11px;
}

.verticalAlignCenter {
    display: flex;
    align-items: center;
    height: 100%;
}

.actionsCard {
    background: #857AEF 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 4px;
}

.multipleOptionsCard {
    background: #857AEF 0% 0% no-repeat padding-box;
    border: none;
}

.specificActionCard {
    min-height: 35px;
    padding: 10px;
    display: flex;
    align-items: center;
    color: #F5F9FD;
    max-width: 250px;
}

.siteCard {
    height: 35px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: #F5F9FD;
}

.deptCard {
    height: 35px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    background: #F5F9FC 0% 0% no-repeat padding-box;
    color: #7165E3;
}

.popoverStyle>* {
    box-shadow: none !important;
}

.specificActionCard:hover {
    background: #F5F9FC 0% 0% no-repeat padding-box;
    color: #7165E3;
    border: 1px solid #7165E3;
    border-radius: 4px;
}

.spaceBetweenColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.noDataTextStyle {
    text-align: center;
    font: normal normal normal 36px/31px Monsterrat;
    letter-spacing: 0px;
    color: #6C6C6C;

    p {
        font: normal normal normal 20px/31px Monsterrat;
    }
}

.noContractsBox {
    border: 2px dashed #c4bef3;
    padding: 20px;
    height: 180px;
    margin: 50px;
}

.noContractsFontStyle {
    font: normal normal bold 32px/40px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
    text-align: center;
}

.noContractsSmallFontStyle {
    font: normal normal bold 20px/25px Montserrat;
    letter-spacing: 0px;
    color: #6c727a;
    text-align: center;
}

.justifyCenter {
    justify-content: center !important;
}

.alignCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.sortImgStyle {
    height: 30px;
    width: 30px;
    object-fit: contain;
}

.CheckboxImgStyle {
    height: 30px;
    width: 30px;
    object-fit: contain;
}

.addStyle {
    background: #7165E3 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 8px;
    height: 33px;
    min-width: 35px;
}