@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.loadingScreenInline {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 2.5s ease-in-out;
  &.inline {
    background: white;
  }
}

.rotating_text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 300px;
  height: 20px;
}
.rotating_text span {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  white-space: nowrap;
  font-weight: 500;
  font-size: 1.1rem;
  left: 0;
  opacity: 0;
  transform: translateX(50%);
  overflow: hidden;
  color: #52575D;
  animation: rotateWord 6s linear infinite 0s;
}
.rotating_text span:nth-child(2) {
  animation-delay: 3s;
}
@keyframes rotateWord {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 0;
    transform: translateY(20px);
  }
  5% {
    opacity: 1;
    transform: translateY(0px);
  }
  17% {
    opacity: 1;
    transform: translateY(0px);
  }
  20% {
    opacity: 0;
    transform: translateY(-20px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.logo{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 250px;
  object-fit: contain;
}

.displayInColumn{
  display: flex;
  flex-direction: column;
}

.fadeIn {
  animation: fade 2.5s linear infinite;
  -webkit-animation: fade 2.5s linear infinite;
  -moz-animation: fade 2.5s linear infinite;
  -o-animation: fade 2.5s linear infinite;
  -ms-animation: fade 2.5s linear infinite;
}

@keyframes fade {
  0%,100% {
     opacity: 0.1;
    }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes fade {
  0%,100% {
    opacity: 0.1;
   }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  0%,100% {
    opacity: 0.1;
   }
  50% {
    opacity: 1;
  }
}

@-o-keyframes fade {
  0%,100% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes fade {
  0%,100% {
    opacity: 0.1;
   }
  50% {
    opacity: 1;
  }
}