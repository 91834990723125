.chevronCardStyle {
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #C4C8D066;
    border-radius: 6px;
    opacity: 1;
    cursor: pointer;
    position: relative;
}

.openCardStyle {
    background-color: #ebe9fb;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chevronRightStyle2 {
    width: 30px;
    height: 30px;
    background: #EBF2F7 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
}

.alignCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.cardStyle {
    height: 105px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #C4C8D066;
    border-radius: 8px;
    opacity: 1;
    cursor: pointer;
    padding: 15px;
}

.marginTop20 {
    margin-top: 20px !important;
}

.userNameStyle {
    text-align: left;
    font: normal normal 600 15px/13px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
    height: 40px;
    width: 100%;
    word-wrap: break-word;
}

.displayInCol {
    display: flex;
    flex-direction: column;
}

.userLogo {
    width: 53px;
    height: 53px;
    border-radius: 50%;

}

.bigCardStyle {
    height: calc(100vh - 320px);
    overflow: scroll;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #C4C8D066;
    border-radius: 8px;
    opacity: 1;
    padding: 5px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.bigCardStyle::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.bigCardStyle {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.footerIconStyle {
    height: 40px;
    width: 40px;
    object-fit: contain;
    margin-top: 5px;
}