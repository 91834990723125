@import '../../styles/mixins.scss';

.spaceBetween{
    display: flex;
    justify-content: space-between;
}

.paginationStyle{
    font: normal normal 600 12px/15px Montserrat;
    letter-spacing: 0px;
    color: #52575D;
    opacity: 1;
    margin-top: 10px;
}

.displayInRow{
    display: flex;
    flex-direction: row;
}

.border{
  border: 1px solid grey;
  border-radius: 40px;
}

.margin{
  margin: 8px 8px 8px 5px;
}

.cursor{
  cursor:pointer;
}

.marginRight{
  margin-right: 20px;
}
