@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');

body {
	font-family: 'Montserrat, Roboto, Proxima Nova';
	scrollbar-width: none !important;
}

.App {
	text-align: center;
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@font-face {
	font-family: 'proxima_nova_rgregular';
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
		url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
		url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
		url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'proxima_nova_rgbold';
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
		url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),
		url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),
		url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'adelle_rgregular';
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix') format('embedded-opentype'),
		url('https://litmus.com/fonts/Emails/adelle_reg-webfont.woff') format('woff'),
		url('https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf') format('truetype'),
		url('https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

body {
	font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
}

h1 {
	font-family: 'proxima_nova_rgbold', Helvetica, Arial, sans-serif;
}

.bp4-overlay-backdrop {
	background: #7165E3 !important;
	border-radius: 5px;
	opacity: 0.5 !important;
}

.bp4-input {
	border: 1px solid #E9E9F0 !important;
	border-radius: 4px !important;
	box-shadow: none !important;
}

.react-datalist-input__textbox {
	border: 1px solid #E9E9F0 !important;
}

select {
	line-height: 1.3;
	padding: 0.6em 1.4em 0.5em 0.8em;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	border-radius: 0.5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: transparent !important;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
		linear-gradient(to bottom, #ffffff 0%, #f5f4f4 100%);
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
}

::-webkit-scrollbar {
	width: 0 !important;
	height: 0 !important;
	display: none !important;
}

.toast-container {
	z-index: 9999;
}

.bp3-toast-container {
	z-index: 999999;
}

.bp4-text-overflow-ellipsis {
	white-space: normal !important;
}

.bp4-tag {
	background-color: #7165E3 !important;
}

.css-euuqmf-MuiInputAdornment-root {
	max-height: 100% !important;
	margin-left: -14px;
	min-width: 60%;
}

.css-1pnmrwp-MuiTypography-root {
	color: #AFB2B6 !important;
	font-size: 12px !important;
}

.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
	text-align: left !important;
}

.bp4-timepicker .bp4-timepicker-input {
	width: 20px !important;
}

.registeredUsers p {
	cursor: default !important;
}