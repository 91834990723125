@import '../../styles/mixins.scss';

.navbarStyle {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

.displayInRow {
    display: flex;
    flex-direction: row;
}

.logo {
    top: 0px;
    left: 42px;
    width: 184px;
    height: 81px;
    opacity: 1;
    margin-right: 80px;
}

.sanmateoLogo {
    position: relative;
    top: 10px;
    left: 22px;
    width: auto;
    height: 60px;
    object-fit: contain;
    margin-right: 80px;
}

.menuStyle {
    margin-top: 30px;
    padding: 0 15px;
    font: normal normal bold 12px/20px Montserrat;
    letter-spacing: 1.5px;
    padding-bottom: 15px;
    color: #646D82;
    cursor: pointer;
}

.logoutStyle {
    margin: 25px 0 0 30px;
    padding: 0 15px;
    font: normal normal bold 15px/25px Montserrat;
    letter-spacing: 1.5px;
    color: #646D82;
}

.activeMenuColor {
    color: #7165E3;
    border-bottom: 2px solid #7165E3;
    padding-bottom: 15px;
    font: normal normal 600 12px/20px Montserrat;
    margin-top: 30px;
}

.icons {
    width: 40px;
    height: 40px;
    margin: 20px 0 0 20px;
    cursor: pointer;
}

.logoutIcons {
    width: 40px;
    height: 40px;
    margin: 20px 20px 0 -10px;
    cursor: pointer;
}

.notificationIcon {
    margin-top: 20px;
    margin-left: -10px;
    width: 20px;
    height: 20px;
}

.notificationCount {
    margin-top: 23px;
    margin-left: -16px;
    width: 12px;
    height: 12px;
    object-fit: cover;
}

.spaceBetween {
    display: flex;
    justify-content: space-between;
}

.optionsCardStyle {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 11px #52575D;
    border-radius: 4px 4px 0px 0px;
    width: 200px;
}

.options {
    padding: 10px;
    font: normal normal 600 12px/15px Montserrat;
    text-align: left;
}

.options:hover {
    background: #EBF2F7 0% 0% no-repeat padding-box;
    color: #7064DE;
}

.noFontStyle {
    text-decoration: none !important;
    color: black;
}

.cursorPointer {
    cursor: pointer !important;
}